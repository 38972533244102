@import "./media.scss";

@mixin inter-font {
  font-family: "Inter";
}

@mixin barlow-font {
  font-family: "barlow";
}

@mixin font-size($size) {
  font-size: ($size / 16) + rem;

  @include for-laptop {
    font-size: ($size / 18) + rem;
  }
}

@mixin line-height($size) {
  line-height: ($size / 16) + rem;
}

@media (max-width: 1400px) {
  @mixin font-size($size) {
    font-size: ($size / 14) + rem;
  }

  @mixin line-height($size) {
    line-height: ($size / 14) + rem;
  }
}
