@import "assets/shared-scss/lib.scss";

.cp-infobox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 50px 0 100px 0;
  max-width: 370px;
  margin: 0 auto;

  .cp-infobox-icon {
    background: #ddf5fc;
    padding: 20px;
    display: inline-flex;
    border-radius: 50px;
    box-shadow: 0 0px 0px 7px #ffffff;
    animation: bounce 900ms 500ms forwards;
    svg {
      width: 30px;
      height: 30px;
      path {
        stroke: #053670;
      }
    }
  }
  .cp-infobox-text {
    flex: 100%;
    @include inter-font;
    @include font-size(32);
    @include line-height(38.6);
    font-weight: 500;
    text-align: center;
    margin-top: 32px;
  }

  .cp-infobox-desc {
    flex: 100%;
    @include inter-font;
    @include font-size(14);
    @include line-height(20);
    font-weight: 400;
    text-align: center;
    margin-top: 18px;
  }
}
