@import "../../../assets/shared-scss/lib.scss";
.MuiButton-root.ct-button-outlined {
  @include inter-font;
  @include font-size(16);
  @include line-height(24);
  font-weight: 500;
  border-radius: 8px;
  padding: 0 20px;
  width: 100%;
  height: 48px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  text-transform: initial;

  svg {
    width: 15px;
    height: 15px;
    path {
    }
  }

  &.Mui-disabled {
    background-color: #c9ced7;
  }
}
