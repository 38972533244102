@import "../../assets/shared-scss/lib.scss";

.cp-activate-account-container {
  display: flex;
  margin: auto;
  padding: 0;
  height: 100vh;

  .cp-activate-account-flex {
    flex: 50%;
  }

  .cp-activate-account-link {
    text-align: right;
    color: $green-color;
  }

  .cp-activate-account-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 35px;
    margin: auto;

    // margin-top: 12.3%;

    .cp-activate-container {
      flex: 100%;
      text-align: center;
      margin-bottom: 22px;
      padding: 0 60px;

      img {
        width: 159px;
      }

      .cp-activate-text {
        @include inter-font;
        @include font-size(30);
        @include line-height(38);
        font-weight: 600;
        margin: 16px 0;
      }
      .cp-activate-desc {
        @include inter-font;
        @include font-size(16);
        @include line-height(20);
        font-weight: 400;
        letter-spacing: 0px;
      }
    }

    .cp-activate-account-options {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 16px;

      .cp-activate-account-message-success {
        margin: 0;
        flex: 100%;
        text-align: center;
      }
      .cp-activate-account-message-description {
        margin: 0;
        text-align: center;
      }
    }

    .cp-activate-account-form {
      display: flex;
      flex-wrap: wrap;
      max-width: 360px;

      .flex-item {
        flex: 100%;
      }

      .email-input {
        margin-bottom: 24px;
      }

      .password-input {
        margin-bottom: 15px;
      }

      .email-us {
        margin-top: 31px;
        width: 100%;
        text-align: center;
        @include inter-font;
        @include font-size(14);
        @include line-height(20);
        font-weight: 400;
        letter-spacing: 0px;

        .cp-activate-account-link {
          padding-left: 5px;
        }
      }

      .cp-activate-account-btn {
        position: relative;
        width: 100%;

        .cp-auth-failed {
          position: absolute;
          width: 100%;
          text-align: center;
          @include inter-font;
          @include font-size(14);
          font-weight: 400;
          letter-spacing: 0px;
          margin: 0;
          color: #f30c2a;
        }
      }
    }
  }

  .tentamus-copyright {
    @include inter-font;
    @include font-size(14);
    @include line-height(20);
    font-weight: 400;
    position: absolute;
    bottom: 32px;
    left: 32px;
    color: #667085;
  }

  .cp-activate-account-image {
    img {
      max-height: 100%;
      height: 100%;
      width: 100%;
      display: flex;
      object-fit: cover;
      object-position: bottom;
    }
  }
}
