@import "assets/shared-scss/lib.scss";

.cp-logout-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .cp-card {
    margin: 0;
    position: relative;
    min-width: 400px;
    padding: 28px 24px;
    text-align: center;

    .cp-modal-logout-close-icon {
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;

      svg path {
        stroke: #98a2b3;
      }
    }

    .cp-modal-logout-icon {
      background: #fef3f2;
      padding: 12px;
      width: 42px;
      height: 42px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      box-shadow: 0 0 0 8px #fef3f285;
      margin-bottom: 20px;
      margin-top: 8px;

      svg path {
        stroke: #f30c2a;
      }
    }

    .cp-logout-modal-title {
      @include barlow-font;
      @include font-size(20);
      @include line-height(24);
      font-weight: 600;
      margin-bottom: 8px;
    }

    .cp-logout-modal-desc {
      @include inter-font;
      @include font-size(14);
      @include line-height(20);
      font-weight: 400;
    }

    .cp-logout-modal-btn {
      display: flex;
      margin-top: 32px;

      .cp-logout-modal-out-btn {
        padding: 0 37px;
        margin-left: 12px;
      }
      .cp-logout-modal-cancel-btn {
        color: #15be7a;
        border-color: #15be7a;
      }
    }
  }
}
