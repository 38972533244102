@use "../../../assets/shared-scss/typography.scss" as *;

.MuiFormControl-root.input {
  p.Mui-error {
    height: 0px;
    position: absolute;
    bottom: -4px;
  }

  .MuiInputLabel-formControl {
    @include inter-font;
    @include font-size(14);
    @include line-height(20);
    color: #0e120f;
    font-weight: 500;
    position: relative;
    top: -20px;
    left: -14px;
  }

  .MuiInputBase-input {
    padding: 13.5px 14px;
  }
  fieldset {
    border-radius: 8px;
    border-color: #c9ced7;
    background: #ffffff;
    z-index: -1;
  }
}
