@import "@fontsource/inter/400";
@import "@fontsource/inter/500";
@import "@fontsource/inter/600";
@import "@fontsource/inter/700";
@import "@fontsource/barlow";
@import "@fontsource/barlow/500";
@import "@fontsource/barlow/600";
@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9fafb !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  outline: 0;
}
@keyframes full-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
