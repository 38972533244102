@import "../../../assets/shared-scss/lib.scss";
.MuiButton-root.ct-button {
  @include inter-font;
  @include font-size(16);
  @include line-height(24);
  font-weight: 500;
  border-radius: 8px;
  padding: 0 70px;
  background-color: $green-color;
  width: 100%;
  height: 48px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #ffffff;
  text-transform: initial;
  border: 0px;

  &.Mui-disabled {
    background-color: #c9ced7;
  }

  .MuiButton-startIcon {
    width: 16px;

    path {
      stroke: #fff;
    }
  }
}
