@import "../../../assets/shared-scss/lib.scss";

.cp-sample-details {
  margin: 0;
  width: 100%;

  .cp-sample-detail-back-link {
    @include inter-font;
    @include font-size(14);
    @include line-height(24);
    color: #0e120f;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-decoration: none;

    svg {
      margin-right: 13px;
    }
  }

  .cp-sample-samples-title {
    @include barlow-font;
    @include font-size(20);
    @include line-height(24);
    font-weight: 600;
    margin-top: 0;
  }
}
