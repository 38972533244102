@import "../../assets/shared-scss/lib.scss";

.cp-page-not-found-container {
  display: flex;
  margin: auto;
  padding: 0;
  height: 100vh;

  .cp-page-not-found-flex {
    flex: 50%;
  }

  .cp-page-not-found-link {
    text-align: right;
    color: $green-color;
  }

  .cp-page-not-found-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 35px;
    margin: auto;

    // margin-top: 12.3%;

    .cp-page-not-found-content-container {
      flex: 100%;
      text-align: center;
      margin-bottom: 22px;
      padding: 0 60px;

      img {
        width: 159px;
      }

      .cp-page-not-found-content-text {
        @include inter-font;
        @include font-size(50);
        @include line-height(100);
        font-weight: 600;
        margin: 16px 0;
      }
      .cp-page-not-found-content-desc {
        @include inter-font;
        @include font-size(16);
        @include line-height(20);
        font-weight: 400;
        letter-spacing: 0px;
      }
    }
  }

  .tentamus-copyright {
    @include inter-font;
    @include font-size(14);
    @include line-height(20);
    font-weight: 400;
    position: absolute;
    bottom: 32px;
    left: 32px;
    color: #667085;
  }

  .cp-page-not-found-image {
    img {
      max-height: 100%;
      height: 100%;
      width: 100%;
      display: flex;
      object-fit: cover;
      object-position: bottom;
    }
  }
}
