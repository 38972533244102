@import "../../../assets/shared-scss/lib.scss";

.cp-data-table {
  .MuiTableContainer-root {
    max-height: 430px;
  }
  .cp-data-table-head-row {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #f5f7f8;

    th {
      @include inter-font;
      @include font-size(12);
      @include line-height(16);
      font-weight: 500;
      border: 0px solid transparent;

      .cp-data-table-head-row-flex {
        display: flex;
        align-items: center;

        .cp-data-table-sort-icon {
          width: 26px;
          min-width: 26px;
          height: 18px;
          transform: rotate(90deg);
          cursor: pointer;
          display: inline-flex;
          vertical-align: middle;
          padding: 2px;

          path {
            stroke: rgba(0, 0, 0, 0.6);
          }
        }
      }

      &:first-child {
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }
      &:last-child {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
      }
    }
  }
  .cp-data-table-content-row {
    @include inter-font;
    @include font-size(14);
    @include line-height(20);
    font-weight: 500;
    color: #0e120f;

    .cp-data-table-new-cell {
      background-color: #dffdf1;
    }
    &:hover {
      .cp-data-table-new-cell {
        background-color: unset;
      }
    }
    &:last-child .cp-data-table-content-cell {
      border-bottom: 0px;
    }
    &.cp-data-table-content-row-loading {
      .cp-data-table-content-cell {
        position: initial;
      }
    }

    .cp-data-table-content-cell {
      border-bottom: 1px solid rgb(201 206 215 / 50%);
      position: relative;
      padding: 26px 16px;

      .cp-data-table-document {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        a {
          position: relative;
          padding: 3px;
        }
        .cp-data-table-downloadall-icon {
          transform: rotate(90deg);
          width: 30px;
          height: 30px;

          path {
            fill: #c5cedb;
            stroke: #f6f6f6;
          }
        }
      }

      a {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        color: unset;
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 26px 16px;
      }

      &.cp-data-table-content-cell-status {
        .cp-status {
          @include font-size(12);
          @include line-height(18);
          font-weight: 500;
          padding: 4px 10px 4px 20px;
          border-radius: 16px;
          display: flex;
          align-items: center;

          .cp-status-dot {
            min-width: 6px;
            min-height: 6px;
            max-width: 6px;
            max-height: 6px;
            display: inline-flex;
            position: relative;
            left: -7px;
            border-radius: 50%;
          }
        }

        .cp-orang-status {
          background: #fff4de;
          color: #ffb016;

          &::before {
            background: #ffb016;
          }
        }

        .cp-green-status {
          color: #15be7a;
          background: #dffdf1;
          &::before {
            background: #15be7a;
          }
        }

        .cp-gray-status {
          background: #f5f7f8;
          color: #383c45;

          &::before {
            background: #383c45;
          }
        }

        .cp-blue-status {
          background: #e2eeff;
          color: #053670;

          &::before {
            background: #053670;
          }
        }
      }

      .cp-data-table-content-cell-summary {
        font-weight: 400;
        color: #383c45;
      }
    }
  }

  .cp-table-pagination {
    p,
    div {
      @include font-size(14);
      @include line-height(20);
      font-weight: 400;
    }
  }

  .cp-table-loading-icon {
    animation-name: full-rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 36px;
    height: 36px;
    padding: 4px;
  }
}
