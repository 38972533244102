@import "./typography.scss";

$green-color: #15be7a;

@keyframes bgColorBlink {
  0% {
    background-color: rgba(26, 27, 38, 0.071);
  }
  50% {
    background-color: rgba(26, 27, 38, 0.144);
  }
  100% {
    background-color: rgba(26, 27, 38, 0.071);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
