@import "assets/shared-scss/lib.scss";

.cp-tooltip {
  //   display: contents !important;
}
.MuiTooltip-popper {
  @include inter-font;
  .MuiTooltip-tooltipArrow {
    background: #0e120f;

    .MuiTooltip-arrow::before {
      background-color: #0e120f;
    }
  }
}
