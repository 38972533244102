@import "../../../../assets/shared-scss/lib.scss";

.cp-sample-info {
  padding-bottom: 47px;

  .cp-sample-info-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .cp-sample-number {
      .cp-sample-number-label {
        flex: 100%;
        @include inter-font;
        @include font-size(14);
        @include line-height(20);
        font-weight: 400;
        color: #383c45;
        margin: 0;
      }

      .cp-sample-number-title {
        flex: 100%;
        @include barlow-font;
        @include font-size(28);
        @include line-height(33.6);
        font-weight: 600;
        color: #0e120f;
        margin: 0;
      }
    }

    .cp-sample-reuse-registration {
      @include inter-font;
      @include font-size(14);
      @include line-height(24);
      font-weight: 500;
      text-decoration: none;
      color: $green-color;
    }
  }

  .cp-sample-slider {
    margin-top: 72px;
    // max-width: 700px;
  }

  .cp-sample-info-table {
    display: flex;

    .cp-sample-info-table-label {
      flex: 180px 0;
      @include inter-font;
      @include font-size(14);
      @include line-height(20);
      font-weight: 500;
      color: #0e120f;
    }

    .cp-sample-info-table-data {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      color: #383c45;

      a {
        @include inter-font;
        @include font-size(14);
        @include line-height(20);
        font-weight: 400;
        color: #383c45;
        text-decoration: none;
        display: flex;
        align-items: center;
        width: fit-content;
        margin-right: 30px;
        svg {
          padding-right: 5px;
        }
      }
    }
  }
}
