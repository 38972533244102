@import "../../assets/shared-scss/lib.scss";

.cp-reset-password-container {
  display: flex;
  margin: auto;
  padding: 0;
  height: 100vh;

  .cp-reset-password-flex {
    flex: 50%;
  }

  .cp-reset-password-link {
    text-align: right;
    color: $green-color;
  }

  .cp-reset-password-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 35px;
    margin: auto;

    // margin-top: 12.3%;

    .welcome-container {
      flex: 100%;
      text-align: center;
      margin-bottom: 22px;
      padding: 0 60px;

      img {
        width: 159px;
      }

      .welcome-text {
        @include inter-font;
        @include font-size(30);
        @include line-height(38);
        font-weight: 600;
        margin: 16px 0;
      }
      .welcome-desc {
        @include inter-font;
        @include font-size(16);
        @include line-height(20);
        font-weight: 400;
        letter-spacing: 0px;
      }
    }
    .cp-reset-password-form {
      display: flex;
      flex-wrap: wrap;
      max-width: 360px;

      .flex-item {
        flex: 100%;
      }

      .email-input {
        margin-bottom: 24px;
      }

      .password-input {
        margin-bottom: 15px;
      }

      .cp-reset-password-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .remember-checkbox {
          flex: 50%;

          .MuiTypography-root {
            @include inter-font;
            @include font-size(14);
            @include line-height(20);
            letter-spacing: 0px;
            font-weight: 400;
          }
          .MuiCheckbox-root {
            padding: 7px;

            .MuiSvgIcon-root {
              width: 22px;
              height: 22px;
              fill: #c9ced7;
              border-radius: 4px;
            }
          }
          .Mui-checked {
            .MuiSvgIcon-root {
              color: $green-color;
              fill: $green-color;
            }
          }
        }
        .forget-pass-link {
          @include inter-font;
          @include font-size(14);
          @include line-height(20);
          font-weight: 500;
          text-align: right;
          flex: 50%;
          color: $green-color;
          letter-spacing: 0px;
        }
      }

      .email-us {
        margin-top: 31px;
        width: 100%;
        text-align: center;
        @include inter-font;
        @include font-size(14);
        @include line-height(20);
        font-weight: 400;
        letter-spacing: 0px;

        .cp-reset-password-link {
          padding-left: 5px;
        }
      }

      .cp-cp-reset-password-btn {
        position: relative;
        width: 100%;

        .cp-auth-failed {
          position: absolute;
          width: 100%;
          text-align: center;
          @include inter-font;
          @include font-size(14);
          font-weight: 400;
          letter-spacing: 0px;
          margin: 0;
          color: #f30c2a;
        }
      }
    }
  }

  .tentamus-copyright {
    @include inter-font;
    @include font-size(14);
    @include line-height(20);
    font-weight: 400;
    position: absolute;
    bottom: 32px;
    left: 32px;
    color: #667085;
  }

  .cp-reset-password-image {
    img {
      max-height: 100%;
      height: 100%;
      width: 100%;
      display: flex;
      object-fit: cover;
      object-position: bottom;
    }
  }
}
