@mixin for-laptop {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin for-tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}
