@import "../../../assets/shared-scss/lib.scss";

.cp-card {
  background: #ffffff;
  box-shadow: 0px 1px 13px rgba(0, 20, 56, 0.1);
  border-radius: 8px;
  padding: 40px 32px 15px 32px;
  margin-top: 36px;

  .cp-card-header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }

  .cp-card-title {
    @include barlow-font;
    @include font-size(20);
    @include line-height(24);
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #0e120f;
  }

  .cp-card-title-icon {
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 12px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background: $green-color;
    svg {
      width: 16px;
      height: 16px;
      path {
        stroke: #fff;
      }
    }
  }

  .cp-card-right-end-item {
    margin-left: auto;
  }
  .cp-export-table-icon {
    width: 20px;
    cursor: pointer;
    path {
      stroke: #98a2b3;
    }
  }
}
